@import './src/styles/variables';

.root {
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: $xl) {
    width: 200px;
  }

  @media screen and (max-width: $md) {
    width: 100%;
    padding-top: 40px;

    &:first-child {
      padding-top: 0;
    }
  }
}

.icon {
  width: 110px;
  height: auto;

  @media screen and (max-width: $xl) {
    width: 85px;

  }
}

.text {
  padding-top: 26px;
  font-family: $fontOpenSans;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 177.18%;
  text-align: center;
  color: $b700;

  @media screen and (max-width: $xl) {
    padding-top: 15px;
    font-size: 16px;
  }
}