@import './src/styles/variables';

.root {
  max-width: 900px;
  padding-top: 45px;
  font-family: $fontOpenSans;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 36px;
  text-align: center;
  color: $ink65;

  sup {
    line-height: 0;
    font-size: 75%;
  }

  @media screen and (max-width: $xl) {
    width: 100%;
    padding-top: 40px;
    font-size: 16px;
    line-height: 27px;
  }
}