@import './src/styles/variables';

.root {
  padding: 30px 0;
  display: flex;
  align-items: center;

  @media screen and (max-width: $xl) {
    padding: 14px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.image {
  width: 235px;
  height: 53px;
  cursor: pointer;

  @media screen and (max-width: $xl) {
    width: 124px;
    height: 28px;
  }
}

.text {
  padding-left: 20px;
  font-family: $fontOpenSans;
  font-style: normal;
  font-weight: 600;
  font-size: 23px;
  line-height: 31px;
  color: $ink0;

  sup {
    line-height: 0;
    font-size: 75%;
  }

  @media screen and (max-width: $xl) {
    padding-top: 5px;
    padding-left: 0;
    font-size: 14px;
    line-height: 19px;
  }
}