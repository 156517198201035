@import './src/styles/variables';

.root {
  width: 100%;
  padding-bottom: 58px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $b700;

  @media screen and (max-width: $xl) {
    padding-bottom: 46px;
    padding-right: 10px;
    padding-left: 10px;
  }
}