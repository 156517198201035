@import './src/styles/variables';

.root {
  padding-top: 64px;
  width: 840px;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: $xl) {
    padding-top: 52px;
    width: 650px;
  }

  @media screen and (max-width: $md) {
    padding-top: 52px;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
}