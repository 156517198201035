@import './src/styles/variables';

.root {
  width: 100%;
  padding: 45px 0;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: $xl) {
    width: 100%;
    padding: 25px 10px;
    flex-direction: column;
  }
}

.container {
  display: flex;

  @media screen and (max-width: $xl) {
    padding-top: 10px;
  }
}

.link, .name {
  margin-right: 40px;
  font-family: $fontOpenSans;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  color: $ink65;

  &:last-child {
    margin-right: 0;
  }
}

.name {
  @media screen and (max-width: $xl) {
    margin-right: 0;
  }
}

.contact {
  cursor: pointer;
}