@import './src/styles/variables';

.root {
  padding-top: 138px;
  font-family: $fontOpenSans;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 50px;
  text-align: center;
  color: $ink85;

  @media screen and (max-width: $xl) {
    padding-top: 110px;
    font-size: 22px;
  }
}