@import './src/styles/variables';

.root {
  width: 100%;
  padding-bottom: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: $xl) {
    padding-bottom: 120px;
    padding-left: 40px;
    padding-right: 40px;
  }

  @media screen and (max-width: $md) {
    padding-left: 30px;
    padding-right: 30px;
  }
}