@import './src/styles/variables';

.root {
  padding-top: 40px;
  font-family: $fontOpenSans;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 40px;
  text-align: center;
  color: $ink0;

  @media screen and (max-width: $xl) {
    padding-top: 35px;
    font-size: 20px;
  }
}