@import './src/styles/variables';

.root {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.image {
  width: 225px;
  height: 225px;

  @media screen and (max-width: $xl) {
    width: 150px;
    height: 150px;
  }
}