@import './src/styles/variables';

.root {
  margin-top: 45px;

  @media screen and (max-width: $xl) {
    margin-top: 30px;
  }
}

.button {

}