@import './src/styles/variables';

.root {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  padding: 0 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  background-color: $b700;

  @media screen and (max-width: $xl) {
    padding: 0 40px;
  }

  @media screen and (max-width: $md) {
    padding: 0 30px;
  }
}

.container {

}