@import './src/styles/variables';

.root {
  width: 100%;
  height: 590px;
  margin-top: 113px;
  padding-right: 180px;
  display: flex;
  justify-content: flex-end;
  background-image: url("../assets/images/hero.jpg");
  background-size: cover;
  background-position-x: center;

  @media screen and (max-width: $xl) {
    height: 380px;
    margin-top: 80px;
    padding-right: 60px;
  }

  @media screen and (max-width: $md) {
    height: 240px;
    margin-top: 80px;
    padding-right: 30px;
    background-position-x: 48%;
  }
}

.image {
  width: 327px;
  height: 634px;

  @media screen and (max-width: $xl) {
    width: 218px;
    height: 423px;
  }

  @media screen and (max-width: $md) {
    width: 142px;
    height: 276px;
  }
}