/* Colors */
$ink100: #000000;
$ink0: #ffffff;

$ink90: #0f1114;
$ink85: #191b1d;
$ink80: #20242a;
$ink70: #41454a;
$ink65: #5e6163;
$ink60: #626569;
$ink50: #85878a;
$ink40: #a6a7aa;
$ink30: #bcbdbf;
$ink20: #d2d3d4;
$ink10: #e8e9e9;
$ink5: #f7f7f7;

$red90: #660a00;
$red80: #8d180b;
$red70: #cb3525;
$red60: #ec5341;
$red50: #ff6a59;
$red40: #ff7f71;
$red30: #ff9589;
$red20: #ffa89e;
$red10: #ffc5bf;
$red5: #ffe2df;

$yellow90: #4d3900;
$yellow80: #8c6900;
$yellow70: #cc9900;
$yellow60: #f2c744;
$yellow50: #ffcc33;
$yellow40: #ffd24d;
$yellow30: #ffe189;
$yellow20: #ffedb8;
$yellow10: #fff5d9;
$yellow5: #fffbf0;

$green90: #2e4d00;
$green80: #548c00;
$green70: #7acc00;
$green60: #9eeb2a;
$green50: #abe556;
$green40: #b8ff4d;
$green30: #d6ff99;
$green20: #e6ffbf;
$green10: #f0ffd9;
$green5: #f9fff0;

$b900: #064566;
$b800: #024E75;
$b700: #005885;
$b600: #2578A2;
$b500: #50A2CB;
$b400: #70BDE4;
$b300: #96D8FA;
$b200: #DBF3FF;
$b150: #F5FDFF;
$b100: #F7FCFF;

$purple70: #8688fb;

$blue60: #32c5ff;

$ygreen40: #d7ef6b;

/* Breakpoints */
$xxl: 1600px;
$xl: 1200px;
$lg: 992px;
$md: 768px;
$sm: 576px;
$xs: 320px;

/* zIndex */
$navigation: 1;
$navigationFade: 2;
$header: 3;
$contactPopup: 4;
$video: 5;

$spacing: 8px;

/* Fonts */
$fontOpenSans: 'Open Sans', sans-serif;
