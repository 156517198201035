@import './src/styles/variables';

.root {
  padding-top: 90px;
  font-family: $fontOpenSans;
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 55px;
  text-align: center;
  color: $ink85;

  @media screen and (max-width: $xl) {
    padding-top: 52px;
    font-size: 26px;
    line-height: 36px;
  }
}