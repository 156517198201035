@import './src/styles/variables';

.root {
  width: 100%;
  padding-bottom: 82px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $b150;

  @media screen and (max-width: $xl) {
    padding-bottom: 54px;
    padding-right: 40px;
    padding-left: 40px;
  }

  @media screen and (max-width: $md) {
    padding-right: 30px;
    padding-left: 30px;
  }
}