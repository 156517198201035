@import './src/styles/variables';

.root {
  display: inline-block;
  padding: 13px 22px;
  border-radius: 11px;
  border: 0;
  cursor: pointer;
  transition: .3s ease-in-out;
  font-family: $fontOpenSans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.05em;
  text-transform: uppercase;

  @media screen and (max-width: $xl) {
    padding: 8px 16px;
    font-size: 12px;
    line-height: 16px;
  }
}

.variantWhite {
  background: $ink0;
  color: $b700;

  &:hover {
    box-shadow: 0px 20.5083px 32.4715px rgba(29, 17, 51, 0.04), 0px 8.54512px 41.0166px rgba(9, 32, 77, 0.12), 0px 10.2541px 13.6722px rgba(29, 17, 51, 0.12);
  }

  &:active {
    background: $ink10;
  }
}

.variantBlue {
  background: $b700;
  color: $ink0;

  &:hover {
    box-shadow: 0px 20.5083px 32.4715px rgba(29, 17, 51, 0.04), 0px 8.54512px 41.0166px rgba(9, 32, 77, 0.12), 0px 10.2541px 13.6722px rgba(29, 17, 51, 0.12);
  }

  &:active {
    background: $b800,
  }
}