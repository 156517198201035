@import './src/styles/variables';

.root {
  max-width: 1010px;
  padding-top: 10px;
  font-family: $fontOpenSans;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  color: $ink65;

  @media screen and (max-width: $xl) {
    width: 100%;
    padding-top: 5px;
    font-size: 14px;
    line-height: 25px;
  }
}