@import '../../../../../../../styles/variables';

.root {
  padding-top: 95px;
  width: 1110px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: $xl) {
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  @media screen and (max-width: $md) {
    padding-top: 70px;
  }
}

.imageContainer {
  max-width: 540px;

  @media screen and (max-width: $md) {
    max-width: 455px;
  }
}

.image {
  width: 100%;
  height: auto;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .icon {
    width: 36px;
    height: auto;

    @media screen and (max-width: $md) {
      width: 30px;
    }
  }

  .title {
    padding-top: 15px;
    font-family: $fontOpenSans;
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 45px;
    color: $ink85;

    @media screen and (max-width: $md) {
      padding-top: 5px;
      font-size: 22px;
    }
  }

  .line {
    margin-top: 15px;
    width: 183px;
    height: 2px;
    background-color: $b700;

    @media screen and (max-width: $md) {
      margin-top: 10px;
    }
  }

  .description {
    padding-top: 30px;
    max-width: 455px;
    font-family: $fontOpenSans;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: $ink65;

    @media screen and (max-width: $xl) {
      max-width: 540px;

    }

    @media screen and (max-width: $md) {
      max-width: 455px;
      padding-top: 15px;
      font-size: 12px;
    }
  }
}